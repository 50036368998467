.graficos-asesor{
    width: 60vw;
}

.recursos{
   width: 56vw;
}
.lista-videos-asesor{
    width: 52vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    .card-video{
        flex: 1;
        margin-right: 1em !important;
    }
}

.buscador-date{
    width: 50vw;
    padding: 0%;
}

.asesor-titulo{
    font-size: 16px;
    color: #1ABBB9;
    background: #EBFDFF;
    border-radius: 8px;
    padding: 8px 12px;
}

.nombre-asesor svg {
    width: 16px;
    margin-bottom: 3px;
    margin-left: 2px;
    margin-right: 16px;
    cursor: pointer;
}

.nombre-asesor{
    color: #262626;
    font-size: 36px;
}

.graficos2.mt-5.ms-3 {
    max-width: 58vw;
}

.highcharts{
    display: flex;
    max-width: 90%;
}

.highcharts > div {
    flex-grow: 1;
    flex-basis: 100%;
    margin-right: 24px;
    border-radius: 15px;
    background: #fff;
    padding: 10px;
}

.highcharts div {
    overflow: hidden;
}
