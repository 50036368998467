//Colors
$black: #000000;
$white: #ffffff;
$blue: #477CEE;
$lightBlue: #5E93FC;
$lightBlueFooter: #00B4EF;
$grey: #595959;
$green: #62D862;
$lightGrey: #465057;
$gold: #8a6700;
$videoButton: #595959;

//Breakpoint;
$mobile-width: 600px;
$mobile-height: 900px;
// $mobile-height: 0;


$desktop-lg-width: 992px;


$desktop-xl-width: 1200px;


$desktop-xxl-width: 1400px;


//input
$input: #959595;
$input-color: #e6e635;